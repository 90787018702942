<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      loading: true,
      errored: false,
      total: 0,
      fields: [
        { key: "id", label: "" },
        { key: "name", label: "Nome", sortable: true, },
        { key: "cpf_cnpj", label: "CPF/CNPJ", sortable: true, },
        { key: "email", label: "E-mail", sortable: true, },
        { key: "cellphone", label: "Celular", sortable: true, },
        { key: "city", label: "Cidade", sortable: true, },
      ],
      items: null,

      sortBy: "name",
      sortDesc: false,
      currentPage: "1",
      perPage: "10",
      filter: "",

      del: {
        modal: false,
        id: null,
      },
    };
  },
  methods: {
    getClientes() {
      this.loading = true;

      api.get("clients").then((response) => {
        if (response.data.status == "success") {
          this.total = response.data.total;
          this.items = response.data.list;
        }
      })
        .catch((error) => {
          this.errored = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showDelete(id) {
      this.del.modal = true;
      this.del.id = id;
    },
    delCliente() {
      if (this.del.id) {
        this.del.modal = false;

        api.delete("clients/" + this.del.id).then((response) => {
          if (response.data.status == "success") {
            this.getClientes();
            this.$toast.success('O cliente foi excluído com sucesso.');
          }
        })
      }
    },
  },
  mounted() {
    this.getClientes();
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>Clientes</h3>
        <p>Gerencie seus clientes e relatórios das suas vendas.</p>
      </div>
    </div>

    <div id="dropdown" class="d-flex mb-4">
      <div class="totals">
        <h6 class="m-0">{{ this.total }}</h6>
        <h6 class="m-0 text-secondary">Total</h6>
      </div>
      <b-dropdown class="bg-white" variant="outline-light">
        <template #button-content>
          Ações
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-item to="clientes/cadastrar">Cadastrar</b-dropdown-item>
      </b-dropdown>
      <b-dropdown class="bg-white" variant="outline-light">
        <template #button-content>
          Por página
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-form><b-form-radio v-model="perPage" value="10">10 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="25">25 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="50">50 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="100">100 itens</b-form-radio></b-dropdown-form>
      </b-dropdown>
    </div>

    <div class="card" v-if="!items">
      <div class="card-body min-vh-50 d-flex justify-content-center align-items-center">
        <b-spinner variant="default" role="status"></b-spinner>
      </div>
    </div>

    <div class="card" v-else-if="items && items.length == 0">
      <div class="card-body min-vh-50 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <i class="far fa-sad-cry font-size-24"></i><br>
          nenhum registro encontrado
        </div>
      </div>
    </div>

    <div class="card" v-else>
      <div class="card-body min-vh-50">
        <div class="table-responsive">
          <b-input v-model="filter" placeholder="Busca" class="mb-4"></b-input>
          <b-table :items="items" :fields="fields" class="table-nowrap table-style table-hover min-vh-50" head-variant="light" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :current-page="currentPage" :per-page="perPage" :filter="filter" responsive>
            <template #cell(cellphone)="row">
              {{ row.item.cellphone }}
            </template>
            <template #cell(city)="row">
              {{ row.item.city }}/{{ row.item.state }}
            </template>
            <template #cell(id)="row">
              <b-dropdown variant="more">
                <template #button-content>
                  <i class="fas fa-ellipsis-h"></i>
                </template>
                <b-dropdown-item :to="'/clientes/' + row.item.id"><i class="bx bx-pencil"></i> editar</b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage" pills align="center"></b-pagination>

    <b-modal v-model="del.modal" modal-class="modal-bottom" hide-header hide-footer>
      <div>
        <h4>Excluir cliente</h4>
        <p>Confirma exclusão do cliente?</p>
        <div class="d-flex">
          <button class="btn btn-default mr-3" v-on:click="delCliente">Confirmar</button>
          <button class="btn btn-outline-link" v-on:click="del.modal = false">Fechar <sup>ESC</sup></button>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>